import React, { useMemo, useRef } from "react";
import {
  CarouselContainer,
  ChefImg,
  ContactGif,
  ContactGrid,
  ContactLabel,
  ContactRow,
  ContactRowLabel,
  ContactRowValue,
  ContactUsContainer,
  ContactUsInner,
  ContactUsInnerCenter,
  ContactUsInnerLeft,
  ContactUsInnerTitle,
  ContactUsSection,
  ContactUsTitle,
  ContactUsWrapper,
  ContactValue,
  ContactValueLink,
  Container,
  ExtendedSection,
  FeaturedMenuContainer,
  FeaturedMenuImgFive,
  FeaturedMenuImgFour,
  FeaturedMenuImgOne,
  FeaturedMenuImgThree,
  FeaturedMenuImgTwo,
  FeaturedMenuLeft,
  FeaturedMenuSection,
  FeaturedMenuText,
  FeaturedMenuTitle,
  FeaturedSectionContainer,
  FirstSection,
  FirstSectionBanner,
  FirstSectionBannerSubtext,
  FirstSectionBannerText,
  FiveSensesContainer,
  FiveSensesDish,
  FiveSensesDishContainer,
  FiveSensesFive,
  FiveSensesFour,
  FiveSensesMobileFlex,
  FiveSensesMobileImg,
  FiveSensesOne,
  FiveSensesSection,
  FiveSensesSmoke,
  FiveSensesThree,
  FiveSensesTwo,
  FollowOnInstaBtn,
  Footer,
  GifContainer,
  Header,
  IGImage,
  IGImageContainer,
  IGLabel,
  InstaContainer,
  KitchenDishImg,
  LandingImg,
  LastSectionCarousel,
  LastSectionCarouselImg,
  LastSectionContainer,
  LastSectionImg,
  LastSectionLogo,
  LastSectionText,
  LatestNewsArrowBtnContainer,
  LatestNewsBtn,
  LatestNewsCaption,
  LatestNewsContainer,
  LatestNewsDate,
  LatestNewsImg,
  LatestNewsImgWrapper,
  LatestNewsInner,
  LatestNewsPicOne,
  LatestNewsPicThree,
  LatestNewsPicTwo,
  LatestNewsReadMoreBtn,
  LatestNewsRight,
  LatestNewsRightCaption,
  LatestNewsRightText,
  LatestNewsText,
  LatestNewsWrapper,
  Logo,
  MapContainer,
  MapIframe,
  MapWrapper,
  MenuImg,
  MenuRow,
  MenuRowTwo,
  OurKitchenAward,
  OurKitchenChefName,
  OurKitchenContainer,
  OurKitchenLeft,
  OurKitchenRight,
  OurKitchenSection,
  OurKitchenSeparator,
  OurKitchenText1,
  OurKitchenText2,
  OurSpecialityContainer,
  OurSpecialityInner,
  OurSpecialitySection,
  OurSpecialtyText2,
  ReserveBtn,
  SecondSectionImg,
  SecondSectionText,
  SecondSectionTextContainer,
  SecondSectionTitle,
  Section,
  SectionWithoutPadding,
  Seperator,
  Social,
  Socials,
  Subtext,
  SwiperContainer,
  Text,
  TextBtn,
  TextLogo,
  ThirdSectionContainer,
  Title,
  ViewMenuBtn,
} from "./Home.styles";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import akabekoLogo from "../../assets/images/akabeko-logo.png";
import akabekoText from "../../assets/images/akabeko-text.png";

import landing1 from "../../assets/images/landing/1.jpeg";
import landing2 from "../../assets/images/landing/2.jpeg";
import landing3 from "../../assets/images/landing/3.png";
import landing4 from "../../assets/images/landing/4.jpeg";
import landing5 from "../../assets/images/landing/5.png";
import landing6 from "../../assets/images/landing/6.jpeg";

import secondSectionImg from "../../assets/images/second-section-extended.png";
import ourStory from "../../assets/images/our-story.png";
import featuredMenu from "../../assets/images/featured-menu.png";
import fiveSenses from "../../assets/images/five-senses.png";
import latestNews from "../../assets/images/latest-news.png";
import ourKitchen from "../../assets/images/our-kitchen.png";
import contactUs from "../../assets/images/contact-us.png";

import menuOne from "../../assets/images/menu/1.jpeg";
import menuTwo from "../../assets/images/menu/2.jpeg";
import menuThree from "../../assets/images/menu/3.jpeg";
import menuFour from "../../assets/images/menu/4.jpeg";
import menuFive from "../../assets/images/menu/5.jpeg";
import menuSix from "../../assets/images/menu/6.jpeg";

// import fiveSensesSmoke from "../../assets/images/five-senses/smoke.png";
import fiveSensesOne from "../../assets/images/five-senses/1.png";
import fiveSensesTwo from "../../assets/images/five-senses/2.png";
import fiveSensesThree from "../../assets/images/five-senses/3.png";
import fiveSensesFour from "../../assets/images/five-senses/4.png";
import fiveSensesFive from "../../assets/images/five-senses/5.png";
import fiveSensesOneMobile from "../../assets/images/five-senses/1_mobile.png";
import fiveSensesTwoMobile from "../../assets/images/five-senses/2_mobile.png";
import fiveSensesThreeMobile from "../../assets/images/five-senses/3_mobile.png";
import fiveSensesFourMobile from "../../assets/images/five-senses/4_mobile.png";
import fiveSensesFiveMobile from "../../assets/images/five-senses/5_mobile.png";

import latestNewsOne from "../../assets/images/latest-news/1.png";
import latestNewsTwo from "../../assets/images/latest-news/2.png";
import latestNewsThree from "../../assets/images/latest-news/3.png";
import newsImg from "../../assets/images/news-img.png";

import chefImg from "../../assets/images/our-kitchen/chef.png";
import sousChefImg from "../../assets/images/our-kitchen/sous-chef.png";
import awardImg from "../../assets/images/our-kitchen/award.png";
import awardImgMobile from "../../assets/images/our-kitchen/award-mobile.png";
import kitchenDish from "../../assets/images/our-kitchen/dish.png";

import igImg1 from "../../assets/images/contact-us/1.png";
import igImg2 from "../../assets/images/contact-us/2.png";
import igImg3 from "../../assets/images/contact-us/3.png";
import igImg4 from "../../assets/images/contact-us/4.png";
import igImg5 from "../../assets/images/contact-us/5.png";
import igImg6 from "../../assets/images/contact-us/6.png";
import gif from "../../assets/images/contact-us/gif.gif";

import insta from "../../assets/images/instagram.png";
import fb from "../../assets/images/facebook.png";
import email from "../../assets/images/email.png";

import lastSectionLogo from "../../assets/images/last-section-logo.png";
import lastSectionImg from "../../assets/images/last-section.png";
import { Observer, observer } from "mobx-react";
import { ResponsiveStore } from "stores";
import useOnScreen from "utils/useOnScreen";

import { Transition } from "react-transition-group";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import { LeftArrow } from "svgs/LeftArrow";
import { RightArrow } from "svgs/RightArrow";

const duration = 500;

const defaultStyle = {
  transition: `${duration}ms ease-in-out`,

  opacity: 0,
  visibility: "hidden",
};

const transitionStyles = {
  entering: {
    opacity: 1,
    visibility: "visible",

    maxHeight: "60vh",
  },
  entered: {
    opacity: 1,
    visibility: "visible",

    maxHeight: "60vh",
  },
  exiting: {
    opacity: 0,
    visibility: "visible",
    maxHeight: 0,
  },
  exited: {
    opacity: 0,
    visibility: "hidden",
    maxHeight: 0,
  },
};

export const INSTAGRAM_URL = "https://instagram.com/Akabeko_Dxb";
export const WHATSAPP_URL = "https://wa.me/+971505618288";
export const SEVENROOMS_URL =
  "https://www.sevenrooms.com/reservations/akabekodxb";
export const FACEBOOK_URL =
  "https://www.facebook.com/profile.php?id=61558964765901";
export const FOODMENU_URL = "https://akabeko.ae/menu/food.pdf";
export const DRINKSMENU_URL = "https://akabeko.ae/menu/drinks.pdf";
export const CALL_URL = "tel:+971505618288";

function Home() {
  const { isMobile } = ResponsiveStore;
  // const [isMenuHovered, setIsMenuHovered] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  const openUrl = (url) => window.open(url, "_blank");

  const openWhatsApp = () => openUrl(WHATSAPP_URL);
  const openInstagram = () => openUrl(INSTAGRAM_URL);
  const openSevenrooms = () => openUrl(SEVENROOMS_URL);
  const openFacebook = () => openUrl(FACEBOOK_URL);
  const openFoodMenu = () => openUrl(FOODMENU_URL);
  const openDrinksMenu = () => openUrl(DRINKSMENU_URL);
  const callPhone = () => openUrl(CALL_URL);

  const swiperOneSlidesPerView = useMemo(() => {
    return isMobile ? 1 : 3;
  }, [isMobile]);
  const swiperTwoSlidesPerView = useMemo(() => {
    return isMobile ? 2 : 6;
  }, [isMobile]);

  return (
    <Container>
      <Header>
        <Logo src={akabekoLogo} />
        <Text>
          <TextLogo src={akabekoText} />
        </Text>
        <ReserveBtn onClick={openSevenrooms}>Reserve</ReserveBtn>
      </Header>
      <FirstSection>
        <FirstSectionBanner>
          <FirstSectionBannerText>
            Dubai's first{"\n"}table-top yakiniku
          </FirstSectionBannerText>

          <FirstSectionBannerSubtext>
            bringing you the finest A5 wagyu experience
          </FirstSectionBannerSubtext>
        </FirstSectionBanner>
        {/* {isMobile ? (
          <Subtext>
            Dubai's first table-top yakiniku bringing you the finest A5 wagyu
            experience
          </Subtext>
        ) : null} */}
        <CarouselContainer>
          <Carousel
            showArrows={false}
            infiniteLoop={true}
            showStatus={false}
            autoPlay
            interval={2500}
            showIndicators
            transitionTime={1000}
            // width={"80vw"}
          >
            <LandingImg src={landing1} />

            <LandingImg src={landing2} />

            <LandingImg src={landing3} />
            <LandingImg src={landing4} />
            <LandingImg src={landing5} />
            <LandingImg src={landing6} />
          </Carousel>
        </CarouselContainer>
      </FirstSection>
      <Section>
        <SecondSectionImg src={secondSectionImg} />
        <SecondSectionTitle src={ourStory} />
        <SecondSectionTextContainer>
          <SecondSectionText>
            Akabeko is a bespoke full-service Japanese Yakiniku restaurant
            experience in Dubai.
          </SecondSectionText>
          <SecondSectionText>
            Serving world class Japanese A5 Wagyu, each cut is cooked to
            perfection at your table by our skilled team.
          </SecondSectionText>
          <SecondSectionText>
            Our nose-to-tail concept includes all of the 'left behinds' and
            'secondaries' often neglected, honoring our incredible product and
            commitment to the sustainable dining movement.
          </SecondSectionText>
          <SecondSectionText>
            Akabeko is a mythical cow in Japanese folklore, wishing all that
            comes across it good health and fortune.
          </SecondSectionText>
        </SecondSectionTextContainer>
      </Section>

      <FiveSensesSection>
        <ThirdSectionContainer>
          <Title src={fiveSenses} />
        </ThirdSectionContainer>
        <FiveSensesContainer>
          <FiveSensesDishContainer>
            {/* <FiveSensesSmoke src={fiveSensesSmoke} /> */}
            <FiveSensesDish loop autoPlay playsInline muted>
              {/* <Video loop autoPlay playsInline muted poster={section1Poster}> */}
              <source src={"/videos/grill.mp4"} type="video/mp4" />
              {/* </Video> */}
            </FiveSensesDish>

            {!isMobile ? (
              <>
                <FiveSensesOne src={fiveSensesOne} />
                <FiveSensesTwo src={fiveSensesTwo} />
                <FiveSensesThree src={fiveSensesThree} />
                <FiveSensesFour src={fiveSensesFour} />
                <FiveSensesFive src={fiveSensesFive} />
              </>
            ) : null}
          </FiveSensesDishContainer>
          {isMobile ? (
            <div>
              <FiveSensesMobileFlex>
                <FiveSensesMobileImg src={fiveSensesOneMobile} />
                <FiveSensesMobileImg src={fiveSensesTwoMobile} />
              </FiveSensesMobileFlex>
              <FiveSensesMobileFlex>
                <FiveSensesMobileImg src={fiveSensesThreeMobile} />
                <FiveSensesMobileImg src={fiveSensesFourMobile} />
              </FiveSensesMobileFlex>
              <FiveSensesMobileFlex>
                <FiveSensesMobileImg src={fiveSensesFiveMobile} />
              </FiveSensesMobileFlex>
            </div>
          ) : null}
        </FiveSensesContainer>
      </FiveSensesSection>

      <FeaturedMenuSection>
        {/* <ThirdSectionContainer>
          <Title src={featuredMenu} />
        </ThirdSectionContainer> */}

        <FeaturedSectionContainer>
          <FeaturedMenuLeft>
            <FeaturedMenuTitle src={featuredMenu} />
            <FeaturedMenuText>
              Experience the pinnacle of Japanese Yakiniku with our Featured
              Menu, showcasing A5 Wagyu prepared tableside to bring you an
              authentic Japanese experience. Thoughtfully crafted by our
              esteemed head chef with the finest seasonal produce from Japan,
              this menu defines the epitome of quality and perfection.
            </FeaturedMenuText>

            <ViewMenuBtn onClick={openFoodMenu}>Food Menu</ViewMenuBtn>
            <TextBtn onClick={openDrinksMenu}>Drinks Menu</TextBtn>
          </FeaturedMenuLeft>
          <SwiperContainer>
            <Swiper
              spaceBetween={16}
              slidesPerView={swiperOneSlidesPerView}
              modules={[Autoplay]}
              // onSlideChange={() => console.log("slide change")}
              // onSwiper={(swiper) => console.log(swiper)}
              autoplay={{
                delay: 0,
                pauseOnMouseEnter: true,
              }}
              // autoplay
              // loopFillGroupWithBlank={!0}
              loop
              speed={5000}
              pagination={{
                el: ".swiper-pagination",
                clickable: !0,
              }}
              // breakpoints={{
              //   768: {
              //     slidesPerView: isMobile ? 1 : 3,
              //   },
              // }}
            >
              <SwiperSlide>
                <MenuImg src={menuOne} />
              </SwiperSlide>
              <SwiperSlide>
                <MenuImg src={menuTwo} />
              </SwiperSlide>
              <SwiperSlide>
                <MenuImg src={menuThree} />
              </SwiperSlide>
              <SwiperSlide>
                <MenuImg src={menuFour} />
              </SwiperSlide>
              <SwiperSlide>
                <MenuImg src={menuFive} />
              </SwiperSlide>
              <SwiperSlide>
                <MenuImg src={menuSix} />
              </SwiperSlide>
            </Swiper>
          </SwiperContainer>
        </FeaturedSectionContainer>

        {/* {isMobile ? (
          <FeaturedMenuContainer>
            <Carousel
              showArrows={false}
              infiniteLoop={true}
              showStatus={false}
              interval={1000}
              dynamicHeight={false}
            >
              <FeaturedMenuImgTwo src={menuTwo} />
              <FeaturedMenuImgOne src={menuOne} />
              <FeaturedMenuImgThree src={menuThree} />
              <FeaturedMenuImgFour src={menuFour} />
              <FeaturedMenuImgFive src={menuFive} />
            </Carousel>

            <ViewMenuBtn>View Menu</ViewMenuBtn>
            <TextBtn>Drinks Menu</TextBtn>
          </FeaturedMenuContainer>
        ) : null} */}
      </FeaturedMenuSection>

      {/* <Section>
        <ThirdSectionContainer>
          <Title src={latestNews} />
        </ThirdSectionContainer>

        <LatestNewsContainer isInView={isVisible} desktop ref={ref}>
          {!isMobile ? (
            <>
              <LatestNewsPicOne
                isInView={isVisible}
                desktop
                src={latestNewsOne}
              />
              <LatestNewsPicTwo
                isInView={isVisible}
                desktop
                src={latestNewsTwo}
              />
              <LatestNewsPicThree
                isInView={isVisible}
                desktop
                src={latestNewsThree}
              />
            </>
          ) : null}

          {!isMobile ? (
            <LatestNewsInner>
              <LatestNewsCaption>NEWS PROMO AND FESTIVE</LatestNewsCaption>
              <LatestNewsDate>12 SEP - 12 OCT 2023</LatestNewsDate>
              <LatestNewsText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </LatestNewsText>
              <LatestNewsBtn>Read More</LatestNewsBtn>
            </LatestNewsInner>
          ) : null}
        </LatestNewsContainer>

        {isMobile ? (
          <LatestNewsInner>
            <LatestNewsCaption>NEWS PROMO AND FESTIVE</LatestNewsCaption>
            <LatestNewsDate>12 SEP - 12 OCT 2023</LatestNewsDate>
            <LatestNewsText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </LatestNewsText>
            <LatestNewsBtn>Read More</LatestNewsBtn>
          </LatestNewsInner>
        ) : null}
      </Section> */}

      <OurKitchenSection>
        <ThirdSectionContainer>
          <Title src={ourKitchen} />
        </ThirdSectionContainer>
        <OurKitchenContainer>
          <OurKitchenRight>
            <ChefImg src={chefImg} />
          </OurKitchenRight>

          <OurKitchenLeft>
            <OurKitchenText1>Our Head Chef</OurKitchenText1>
            <OurKitchenChefName>Yosuke Matsuoka</OurKitchenChefName>

            {/* <OurKitchenSeparator /> */}

            <OurKitchenText2>
              At Akabeko, you'll savor the finest seasonal ingredients,
              hand-selected by our illustrious head chef, Yosuke Matusoka.
              {"\n\n"}
              Chef Yosuke, hailed by Gault&Millau as a top-tier Japanese
              culinary expert, brings over 30 years of experience perfecting his
              craft at top restaurants across the globe. His exceptional
              leadership was recognized at the World's Culinary Awards 2022,
              where his team clinched the prestigious title of Middle East's
              Best Hotel Restaurant.
            </OurKitchenText2>

            <OurKitchenAward src={isMobile ? awardImgMobile : awardImg} />
          </OurKitchenLeft>
        </OurKitchenContainer>

        <OurKitchenContainer sous>
          <OurKitchenLeft>
            <OurKitchenText1>Our Sous Chef</OurKitchenText1>
            <OurKitchenChefName>Hiroyuki Suyama</OurKitchenChefName>

            {/* <OurKitchenSeparator /> */}

            <OurKitchenText2>
              Chef Hiroyuki brings expertise honed over six years in
              Michelin-starred kitchens, including the two-star Ristorante
              Piccolo Lago and three-star Ristorante Villa Crespi. His journey
              intertwined with Chef Yosuke at the prestigious Grand Hotel
              Cocumella in Italy.
              {"\n\n"}
              Returning to Japan, Chef Hiroyuki dedicated eight years to product
              development and launched a successful Japanese soup specialty
              business, deeply rooted in traditional Japanese culinary arts. At
              Akabeko, he infuses every dish with his profound heritage and
              diverse expertise, elevating your dining experience to new
              heights.
            </OurKitchenText2>
          </OurKitchenLeft>

          <OurKitchenRight>
            <ChefImg src={sousChefImg} />
          </OurKitchenRight>
        </OurKitchenContainer>
      </OurKitchenSection>

      <OurSpecialitySection>
        <OurSpecialityContainer>
          <OurSpecialityInner img={true}>
            <KitchenDishImg src={kitchenDish} />
          </OurSpecialityInner>

          <OurSpecialityInner>
            <OurKitchenText1>Our Speciality</OurKitchenText1>
            <OurKitchenChefName>A5 Wagyu Beef</OurKitchenChefName>

            {/* <OurKitchenSeparator /> */}

            <OurSpecialtyText2>
              Savor the unparalleled luxury of A5 Wagyu beef, renowned for its
              superior marbling, exceptional tenderness, and dissolving
              sweetness. Sourced from the finest-bred Japanese cattle, this
              prestigious beef undergoes a unique rearing process and strict
              grading standards, making it a true delicacy. The result is a
              melt-in-your-mouth experience that sets the gold standard of
              culinary excellence.
              {"\n\n"}
              Each cut is masterfully prepared at your table by our expert team,
              delivering an authentic umami experience. Embracing a nose-to-tail
              philosophy, we honor every part of the cow, including the
              often-overlooked 'left behinds,' blending sustainability with
              extraordinary taste.
              {"\n\n"}
              At Akabeko, we are dedicated to presenting the finest A5 Wagyu,
              creating a dining experience that is both indulgent and
              unforgettable.
            </OurSpecialtyText2>
          </OurSpecialityInner>
        </OurSpecialityContainer>
      </OurSpecialitySection>

      <Section>
        <ThirdSectionContainer>
          <Title src={latestNews} />
        </ThirdSectionContainer>

        <LatestNewsWrapper ref={ref}>
          <LatestNewsImgWrapper>
            {/* <LatestNewsImg src={newsImg} /> */}

            <Transition
              unmountOnExit
              timeout={{
                appear: duration,
                enter: duration,
                exit: 100,
              }}
              in={isVisible}
            >
              {(state) => (
                <LatestNewsImg
                  src={newsImg}
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                />
              )}
            </Transition>
          </LatestNewsImgWrapper>

          <LatestNewsRight>
            <LatestNewsRightCaption>
              Japanese BBQ restaurant Akabeko is opening in Dubai
            </LatestNewsRightCaption>

            <LatestNewsRightText>
              Dubai is home to a range of cuisines from around the world. Now,
              it is set to welcome the Japanese restaurant. Akabeko. 
            </LatestNewsRightText>

            <LatestNewsReadMoreBtn onClick={openInstagram}>
              Read More
            </LatestNewsReadMoreBtn>

            {/* TODO: un-comment once more news articles are there */}
            {/* <LatestNewsArrowBtnContainer>
              <LeftArrow />
              <RightArrow />
            </LatestNewsArrowBtnContainer> */}
          </LatestNewsRight>
        </LatestNewsWrapper>
      </Section>

      <ContactUsSection>
        {/* <ThirdSectionContainer>
          <Title src={contactUs} />
        </ThirdSectionContainer> */}

        <ContactUsWrapper>
          <ContactUsContainer>
            <ContactUsInnerTitle>
              <ContactUsTitle src={contactUs} />
              <ContactGif src={gif} />
            </ContactUsInnerTitle>

            <ContactUsInner>
              <ContactLabel>Opening Hours</ContactLabel>
              <ContactValue>
                Monday to Sunday:{"\n"}12.00pm - 04.00pm{"\n"}06.00pm - 11.00pm
              </ContactValue>

              <ContactLabel>Phone</ContactLabel>
              <ContactValue onClick={callPhone}>+971-505618288</ContactValue>
              <ContactValueLink onClick={openWhatsApp}>
                WhatsApp Us
              </ContactValueLink>

              <ContactLabel>Email</ContactLabel>
              <ContactValue>contact@akabeko.com</ContactValue>

              <ContactLabel>Address</ContactLabel>
              <ContactValue>
                G01, voco Dubai the Palm Hotel, {"\n"}Palm West Beach
              </ContactValue>
            </ContactUsInner>

            <ContactUsInner>
              <ContactLabel>Dresscode</ContactLabel>
              <ContactValue>
                Smart casual. Sportwear & beachwear is not permitted. Open toed
                shoes for gentlemen are not permitted, except when in national
                attire.
              </ContactValue>

              <ContactLabel>Venue</ContactLabel>
              <ContactValue>
                As a licensed venue, service is restricted to guests 21 and
                older. Guests under 21 must be accompanied by an adult.
              </ContactValue>

              <ContactLabel>Parking</ContactLabel>
              <ContactValue>
                Valet is available at the front of Voco Hotel, with validation
                provided for a minimum table spend of AED 250.
                {"\n\n"}
                The venue is also accessible from West Beach public parking.
              </ContactValue>

              <Socials>
                <Social onClick={openInstagram} src={insta} />
                <Social onClick={openFacebook} src={fb} />
                {/* <Social src={email} /> */}
              </Socials>
            </ContactUsInner>
          </ContactUsContainer>

          <MapWrapper>
            <MapContainer id="display-google-map">
              <MapIframe src="https://www.google.com/maps/embed/v1/place?q=Akabeko,Palm+Jumeirah,Dubai&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8&zoom=12"></MapIframe>
            </MapContainer>
          </MapWrapper>
        </ContactUsWrapper>
      </ContactUsSection>

      <SectionWithoutPadding>
        <LastSectionCarousel>
          <Swiper
            spaceBetween={2}
            slidesPerView={swiperTwoSlidesPerView}
            modules={[Autoplay]}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            autoplay={{
              delay: 0,
              pauseOnMouseEnter: false,
            }}
            // autoplay
            // loopFillGroupWithBlank={!0}
            loop
            speed={3000}
            pagination={{
              el: ".swiper-pagination",
              clickable: !0,
            }}
            // breakpoints={{
            //   768: {
            //     slidesPerView: isMobile ? 2 : 6,
            //   },
            // }}
          >
            <SwiperSlide>
              <LastSectionCarouselImg src={landing1} />
            </SwiperSlide>
            <SwiperSlide>
              <LastSectionCarouselImg src={landing2} />
            </SwiperSlide>
            <SwiperSlide>
              <LastSectionCarouselImg src={landing3} />
            </SwiperSlide>
            <SwiperSlide>
              <LastSectionCarouselImg src={landing4} />
            </SwiperSlide>
            <SwiperSlide>
              <LastSectionCarouselImg src={landing5} />
            </SwiperSlide>
            <SwiperSlide>
              <LastSectionCarouselImg src={landing6} />
            </SwiperSlide>
            <SwiperSlide>
              <LastSectionCarouselImg src={landing1} />
            </SwiperSlide>
            <SwiperSlide>
              <LastSectionCarouselImg src={landing2} />
            </SwiperSlide>
            <SwiperSlide>
              <LastSectionCarouselImg src={landing3} />
            </SwiperSlide>
            <SwiperSlide>
              <LastSectionCarouselImg src={landing4} />
            </SwiperSlide>
            <SwiperSlide>
              <LastSectionCarouselImg src={landing5} />
            </SwiperSlide>
            <SwiperSlide>
              <LastSectionCarouselImg src={landing6} />
            </SwiperSlide>
          </Swiper>
        </LastSectionCarousel>
        <LastSectionContainer>
          {/* <LastSectionLogo src={lastSectionLogo} /> */}

          <InstaContainer>
            <LastSectionText>@akabeko_dxb</LastSectionText>
            <FollowOnInstaBtn onClick={openInstagram}>
              Follow us on Instagram
            </FollowOnInstaBtn>
          </InstaContainer>

          <LastSectionImg src={lastSectionImg} />
          <Footer>2023© AKABEKO</Footer>
        </LastSectionContainer>
      </SectionWithoutPadding>
    </Container>
  );
}

export default observer(Home);
