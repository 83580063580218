import styled from "@emotion/styled";

import latestNewsBg from "../../assets/images/latest-news/main.png";

export const DISPLAY_SIZES = {
  MOBILE_S: "320px",
  MOBILE_M: "375px",
  MOBILE_L: "425px",
  TABLET: "768px",
  LAPTOP: "1024px",
  LAPTOP_L: "1440px",
  DESKTOP: "1920px",
  DESKTOP_L: "2560px",
  DESKTOP_XL: "3840px",
};

export const Container = styled.div`
  background-color: #1a1717;
  color: white;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  /* overflow: auto; */
  /* -webkit-scroll-snap-type: y mandatory;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory; */
  box-sizing: border-box;
  position: relative;
`;

export const Section = styled.div`
  height: 100vh;
  width: 100vw;
  scroll-snap-align: start;
  padding: 24px 64px;
  position: relative;
  box-sizing: border-box;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 24px;
    height: unset;
    min-height: 100vh;
  }
`;

export const FirstSection = styled(Section)`
  /* background-color: #1a1717; */
  z-index: 10;
  padding-bottom: 0;
  padding-top: 120px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 16px;
    padding-top: 60px;
  }
`;

export const ExtendedSection = styled(Section)`
  height: 250vh;
`;

export const FirstSectionBanner = styled.div`
  background-color: rgba(26, 23, 23, 0.8);
  border-radius: 16px;
  padding: 20px 80px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 120px;
  z-index: 998;
  width: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    top: 60px;
    max-width: 70vw;
    padding: 32px;
  }
`;

export const FirstSectionBannerText = styled.h1`
  /* New/H1 */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 100%;
  /* or 60px */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  white-space: pre-wrap;
  margin: auto;

  /* White */
  color: #fcfcfc;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 32px;
  }
`;

export const FirstSectionBannerSubtext = styled.h4`
  /* New/H4 */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */
  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;
  margin-top: 8px;

  /* White */
  color: #fcfcfc;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;
  }
`;

export const ReserveBtn = styled.button`
  position: absolute;
  /* width: 145px; */
  /* height: 74px; */
  padding: 16px 60px;
  right: 60px;
  top: 24px;
  z-index: 15;

  background: #e40032;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 40px;

  border: none;

  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  /* Body/large */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  transition: 0.2s;

  :hover {
    /* transform: scale(0.97); */
    background-color: #fff;
    color: #e40032;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 12px 24px;
    font-size: 14px;
    line-height: 130%;
    right: 16px;
    top: 16px;
    font-weight: 600;

    /* top: unset;
    bottom: 2vh;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    z-index: 12;
    padding: 16px 48px; */
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 32px;
  margin: 0 auto;
  width: fit-content;
  position: fixed;
  z-index: 1999;
  background-color: #1a1717;
  width: 100vw;
  box-sizing: border-box;
  gap: 24px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    justify-content: flex-start;
    padding: 16px;
    max-width: 100vw;
  }
`;

export const Logo = styled.img`
  height: 64px;
  width: 64px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 12vw;
    width: 12vw;
  }
`;

export const Seperator = styled.div`
  /* width: 1px; */
  background-color: white;
  height: 120px;
  margin: 0 24px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    margin: 0 16px;
  }
`;

export const Text = styled.div``;

export const TextLogo = styled.img`
  height: 56px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: unset;
    width: 40vw;
  }
`;

export const Subtext = styled.div`
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #ffffff;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 80vw;
    margin: auto;
    text-align: center;
  }
`;

export const CarouselContainer = styled.div`
  height: calc(100vh - 200px);
  margin-top: 48px;
  display: flex;
  justify-content: center;
  border-radius: 20px;

  > div {
    height: calc(100vh - 200px);
    border-radius: 20px;

    > div:nth-child(1) {
      height: calc(100vh - 200px);
      border-radius: 20px;
    }
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    > div {
      > div:nth-child(1) {
        > div {
          height: 100%;

          > ul {
            height: 100%;
          }
        }
      }
    }
  }
`;

export const LandingImg = styled.img`
  /* width: 80vw; */
  /* height: calc(100vh - 296px); */
  margin: auto;
  display: flex;
  aspect-ratio: 2976  / 1640;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 100%;
    aspect-ratio: 1640 / 2976;
    object-fit: cover;
  }
`;

export const SecondSectionImg = styled.img`
  width: 100vw;
  /* height: 200vh; */
  /* bottom: 0; */
  left: 0;
  right: 0;
  top: -144px;
  position: absolute;
  z-index: 1;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP}) {
    top: 0;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    top: 64vh;
  }
`;

export const SecondSectionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 480px;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  margin-right: 25vw;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 75vw;
    margin-left: 10vw;
  }
`;

export const Title = styled.img`
  height: 64px;
  margin-top: 120px;
  margin-bottom: 24px;
  z-index: 10;
  position: relative;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 48px;
    max-width: 90vw;
    box-sizing: border-box;
    margin-bottom: 0;

    margin-top: 20px;
    margin-left: 0;
  }
`;

export const SecondSectionTitle = styled(Title)`
  margin-left: 17vw;
`;

export const SecondSectionText = styled.div`
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: left;
  letter-spacing: 0.04em;
  margin-bottom: 16px;
  /* margin-left: 10vw; */

  margin-top: 24px;

  color: #ffffff;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;

    @media (max-height: 700px) {
      font-size: 15px;
    }
  }
`;

export const ThirdSectionContainer = styled.div`
  position: relative;
  /* padding-top: 80px; */
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 450px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: calc(100vw - 48px);
  }
`;

// Featured Menu
export const FeaturedMenuSection = styled(Section)`
  min-height: 60vh;
  /* display: flex;
  align-items: center; */
  padding-top: 64px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 120vh;
  }
`;

export const FeaturedSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 64px;
  /* width: 80vw; */
  align-items: center;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-direction: column;
    gap: 32px;
  }
`;

export const FeaturedMenuLeft = styled.div`
  min-width: 30vw;
  z-index: 999;
`;

export const SwiperContainer = styled.div`
  width: 60vw;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 100%;
  }
`;

export const FeaturedMenuText = styled.div`
  /* New/Body */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  /* or 27px */
  display: flex;
  align-items: center;

  color: #fff;
  margin: 40px 0;

  max-width: 350px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-weight: 500;
    font-size: 16px;
  }
`;

export const FeaturedMenuContainer = styled.div`
  position: relative;
  /* width: 100%; */
  /* height: calc(100vh - 398px); */
  z-index: 10;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    margin-top: 10vh;

    @media (max-height: 700px) {
      margin-top: 0;
    }
  }
`;

export const MenuRow = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: -40px;
  align-items: center;
  margin-top: 32px;
`;

export const MenuRowTwo = styled(MenuRow)`
  margin-top: -80px;
`;

export const FeaturedMenuImg = styled.img<{
  hover?: boolean;
  desktop?: boolean;
}>`
  /* position: absolute; */
  height: 30vh;

  transition: margin 0.3s;

  ${({ desktop }) => (desktop ? "" : "height: unset !important;")}
`;

export const FeaturedMenuImgOne = styled(FeaturedMenuImg)`
  /* left: 40vw;
  top: 0vh; */
  /* height: 35vh; */

  ${({ hover, desktop }) =>
    hover && desktop
      ? ""
      : "margin-right: -16px; margin-bottom: -16px; margin-left: -16px;"}
`;

export const FeaturedMenuImgTwo = styled(FeaturedMenuImg)`
  /* left: 25vw;
  top: 0vh; */
  /* height: 40vh; */
`;

export const FeaturedMenuImgThree = styled(FeaturedMenuImg)`
  /* right: 21vw;
  top: 4vh; */
  /* height: 33vh; */
`;

export const FeaturedMenuImgFour = styled(FeaturedMenuImg)`
  /* left: 34vw;
  bottom: 7vh; */
  height: 32vh;

  ${({ hover, desktop }) => (hover && desktop ? "" : "margin-right: -8px;")}
`;

export const MenuImg = styled.img`
  height: 400px;
  width: 333px;
  object-fit: cover;
  border-radius: 16px;
`;

export const FeaturedMenuImgFive = styled(FeaturedMenuImg)`
  height: 33vh;
  /* right: 30vw;
  bottom: 6vh; */

  ${({ hover, desktop }) => (hover && desktop ? "" : "margin-left: -8px;")}
`;

export const FeaturedMenuTitle = styled(Title)`
  margin-top: 0;
`;

export const ViewMenuBtn = styled.button`
  /* Button / main */

  /* Rectangle 5 */
  /* width: 184px;
  height: 74px; */

  padding: 16px 60px;

  background: #e40032;
  color: #fff;
  border-radius: 40px;

  /* Button */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  /* Main BG */
  /* color: #1a1717; */

  /* margin: auto; */
  cursor: pointer;

  outline: unset;
  border-color: transparent;
  font-weight: 500;

  transition: 0.2s;

  :hover {
    background: #fff;
    color: #e40032;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 100%;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
  }
`;

export const TextBtn = styled.button`
  /* Body/medium */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.04em;
  font-weight: 500;
  padding: 16px 60px;

  color: #e40032;
  /* margin: auto; */
  display: block;
  background: unset;
  border: unset;
  cursor: pointer;

  border: 1px solid #e40032;
  border-radius: 40px;

  margin-top: 24px;

  transition: 0.2s;

  :hover {
    border-color: #fff;
    color: #fff;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 100%;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
  }
`;

// Five Senses
export const FiveSensesSection = styled(Section)`
  height: 120vh;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    min-height: 140vh;
    height: unset;
    margin-bottom: 32px;
  }
`;

export const FiveSensesContainer = styled.div<{ isMobile?: boolean }>`
  position: relative;
  height: 100vh;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: calc(100vh - 144px);
  }

  ${({ isMobile }) =>
    isMobile
      ? ""
      : `
  `}
`;

export const FiveSensesMobileFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
`;

export const FiveSensesMobileImg = styled.img`
  /* height: 40vw; */
  width: 40vw;
`;

export const FiveSensesDishContainer = styled.div`
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 90%;
    /* bottom: -24px; */
    /* top: 32px; */
    position: static;
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export const FiveSensesDish = styled.video`
  width: 30vw;
  height: 30vw;
  border-radius: 50%;
  margin: auto;
  z-index: 2;
  object-fit: cover;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 60vw;
    width: 60vw;
  }
`;

export const FiveSensesSmoke = styled.img`
  height: 80vh;
  position: absolute;
  top: -50vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 50vh;
    top: -40vh;
  }
`;

export const FiveSensesOne = styled.img`
  height: 192px;
  position: absolute;
  top: 0px;
  left: -192px;
  z-index: 2;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    left: -36px;
    top: -176px;
    height: 176px;

    @media (max-height: 700px) {
      height: 144px;
      top: -144px;
    }
  }
`;
export const FiveSensesTwo = styled.img`
  height: 192px;
  position: absolute;
  bottom: -96px;
  left: -80px;
  z-index: 2;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP}) {
    left: -48px;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    left: -36px;
    top: -352px;
    height: 176px;

    @media (max-height: 700px) {
      height: 144px;
      top: -288px;
    }
  }
`;
export const FiveSensesThree = styled.img`
  height: 192px;
  position: absolute;
  bottom: -256px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    top: -67vh;
    height: 176px;

    @media (max-height: 700px) {
      height: 144px;
      top: -63vh;
    }
  }
`;
export const FiveSensesFour = styled.img`
  height: 192px;
  position: absolute;
  bottom: -96px;
  right: -80px;
  z-index: 2;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP}) {
    right: -48px;

    @media (max-height: 700px) {
      height: 144px;
    }
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    right: -36px;
    top: -352px;
    height: 176px;

    @media (max-height: 700px) {
      height: 144px;
      top: -288px;
    }
  }
`;
export const FiveSensesFive = styled.img`
  height: 192px;
  position: absolute;
  top: 0px;
  right: -192px;
  z-index: 2;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    right: -36px;
    top: -176px;
    height: 176px;

    @media (max-height: 700px) {
      height: 144px;
      top: -144px;
    }
  }
`;

// Latest News

export const LatestNewsContainer = styled.div<{
  desktop?: boolean;
  isInView?: boolean;
}>`
  position: relative;
  margin: auto;
  width: 70%;
  background-image: url(${latestNewsBg});
  aspect-ratio: 2330 / 1180;
  background-size: contain;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 1s;
  opacity: 1;
  transform: scale(1);

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 90%;
  }

  @media (max-width: ${DISPLAY_SIZES.LAPTOP}) {
    width: 80%;
  }

  ${({ isInView, desktop }) =>
    isInView && desktop
      ? ""
      : `
  opacity: 0;
  transform: scale(0.2);
  `}
`;

export const LatestNewsInner = styled.div`
  width: 75%;
  margin: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    align-items: center;
    text-align: center;
    width: 90%;
    margin-top: 32px;
    gap: 16px;
  }
`;

export const LatestNewsCaption = styled.div`
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 38px;
  line-height: 44px;
  /* identical to box height, or 116% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;

  color: #ffffff;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 20px;
    line-height: 22px;
  }
`;

export const LatestNewsDate = styled.div`
  /* Body/small */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.04em;

  color: #ffffff;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const LatestNewsText = styled.div`
  /* Body/medium */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.04em;

  color: #ffffff;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;
    line-height: 20px;

    @media (max-height: 700px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const LatestNewsBtn = styled.button`
  /* Button / main */

  /* Rectangle 5 */
  /* width: 184px;
  height: 74px; */

  padding: 16px 32px;

  background: #ffffff;
  border-radius: 40px;

  /* Button */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  /* Main BG */
  color: #1a1717;

  cursor: pointer;

  outline: unset;
  border-color: transparent;
`;

export const LatestNewsPicOne = styled.img<{
  desktop?: boolean;
  isInView?: boolean;
}>`
  height: 256px;
  width: 256px;
  position: absolute;
  left: -160px;
  top: 0;

  transition: 1.5s;

  ${({ isInView, desktop }) =>
    isInView && desktop
      ? ""
      : `
    left: 30vw;
    top: 10vh;
  `}
`;

export const LatestNewsPicTwo = styled.img<{
  desktop?: boolean;
  isInView?: boolean;
}>`
  height: 256px;
  width: 256px;
  position: absolute;
  right: 0;
  bottom: -100px;

  transition: 1.5s;

  ${({ isInView, desktop }) =>
    isInView && desktop
      ? ""
      : `
    bottom: 10vh;
    right: 30vw;
  `}
`;

export const LatestNewsPicThree = styled.img<{
  desktop?: boolean;
  isInView?: boolean;
}>`
  height: 160px;
  width: 160px;
  position: absolute;
  right: -120px;
  top: -64px;

  transition: 1.5s;

  ${({ isInView, desktop }) =>
    isInView && desktop
      ? ""
      : `
    top: 10vh;
    right: 30vw;
  `}
`;

export const LatestNewsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 40px 0;
  gap: 64px;
  justify-content: center;
  max-width: 80vw;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-direction: column;
    align-items: center;
    margin: auto;
    gap: 0px;
  }
`;

export const LatestNewsImgWrapper = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
`;

export const LatestNewsImg = styled.img`
  aspect-ratio: 800/480;
  border-radius: 20px;
  max-height: 60vh;
  width: 80vh;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 80vw;
  }
`;

export const LatestNewsRight = styled.div`
  /* margin-top: 64px; */
  flex: 0;
  min-width: 20vw;
`;

export const LatestNewsRightCaption = styled.h3`
  /* New/H3 */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 120%;

  /* White */
  color: #fcfcfc;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 24px;
  }
`;

export const LatestNewsRightText = styled.div`
  /* New/Body Small */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  /* Light Gray */
  color: #cbcbcb;

  margin-top: 36px;
  margin-bottom: 36px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const LatestNewsReadMoreBtn = styled.button`
  /* Button/ desktop */

  box-sizing: border-box;
  background-color: unset;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 60px;
  gap: 10px;

  /* Akabeko Red */
  border: 1px solid #e40032;
  color: #e40032;
  border-radius: 32px;

  /* New/Button */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;

  cursor: pointer;

  transition: 0.2s;

  :hover {
    border-color: #fff;
    color: #fff;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 100%;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
  }
`;

export const LatestNewsArrowBtnContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 36px;
  cursor: pointer;
`;

// Our Kitchen

export const OurKitchenSection = styled(Section)`
  height: unset;
`;

export const OurKitchenContainer = styled.div<{ sous?: Boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  gap: 120px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    gap: 8px;
    width: 90%;

    flex-direction: ${({ sous }) => (sous ? "column-reverse" : "column")};
  }
`;

export const OurKitchenLeft = styled.div`
  flex: 3;
`;

export const OurKitchenText1 = styled.div`
  /* Body/medium */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;

  color: #ffffff;

  margin-bottom: 8px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const OurKitchenChefName = styled.div`
  /* Subheader/large */
  /* font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 38px;
  line-height: 44px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #ffffff; */

  /* Yosuke Matsuoka */

  /* New/H3 */
  font-family: "Futura PT", "Futura PT Book";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;

  margin-bottom: 40px;

  /* Akabeko Red */
  color: #e40032;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 26px;
    line-height: 28px;
  }
`;

export const OurKitchenSeparator = styled.div`
  width: 256px;
  height: 4px;
  background-color: #e40032;

  margin-top: 24px;
  margin-bottom: 48px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 128px;
    margin-top: 12px;
    margin-bottom: 16px;
    height: 2px;
  }
`;

export const OurKitchenText2 = styled.div`
  /* Body/medium */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.04em;

  color: #ffffff;

  white-space: pre-wrap;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;
    line-height: 20px;

    @media (max-height: 700px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const OurSpecialtyText2 = styled.div`
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  color: #ffffff;
  white-space: pre-wrap;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-weight: 500;
    font-size: 16px;
  }
`;

export const OurKitchenAward = styled.img`
  height: 96px;

  margin-top: 48px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    margin: 0 auto;
    height: unset;
    width: 100%;
    margin-top: 24px;
  }
`;

export const OurKitchenRight = styled.div`
  flex: 2;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const ChefImg = styled.img`
  width: 100%;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    /* width: 70%; */
    margin: 32px auto;
    /* @media (max-height: 700px) {
      width: 50%;
    } */
  }
`;

export const KitchenDishImg = styled.img`
  width: 120%;
  margin-left: -20vw;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 120vw;
    margin: 0 auto;

    /* @media (max-height: 700px) {
      width: 50%;
    } */
  }
`;

export const OurSpecialitySection = styled(Section)`
  overflow: hidden;
`;

export const OurSpecialityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  gap: 64px;
  height: 100%;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-direction: column-reverse;
    gap: 24px;
    padding: 48px 0;
  }
`;

export const OurSpecialityInner = styled.div<{ img?: boolean }>`
  flex: 1;

  ${({ img }) =>
    img
      ? `
  
  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-left: 20vw;
  }
  `
      : ""}
`;

// Contact Us

export const ContactUsSection = styled(Section)`
  margin-bottom: 96px;
`;

export const ContactUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 80vw;
  margin: auto;
  padding-bottom: 80px;
`;

export const ContactUsTitle = styled(Title)`
  margin-top: 0;
`;

export const ContactUsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-direction: column;
  }
`;

export const ContactUsInnerTitle = styled.div`
  flex: 1;
`;

export const ContactUsInner = styled.div`
  flex: 1;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    margin-left: 20vw;
  }
`;

export const ContactLabel = styled.div`
  /* New/Body */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 27px */

  color: #595959;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ContactValue = styled.div`
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;
  max-width: 300px;

  margin-bottom: 32px;

  color: #fcfcfc;

  white-space: pre-wrap;
  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ContactValueLink = styled(ContactValue)`
  text-decoration: underline;
  margin-top: -32px;
  cursor: pointer;
`;

export const ContactUsInnerLeft = styled.div`
  flex: 1;
  padding-right: 48px;
`;

export const ContactUsInnerCenter = styled.div`
  flex: 2;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    margin-top: 15vh;
  }
`;

export const IGLabel = styled.div`
  /* Body/medium */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.04em;

  color: #ffffff;
  margin-bottom: 12px;
`;

export const IGImageContainer = styled.div`
  display: grid;
  grid-template-columns: 0fr 0fr;
  gap: 12px;
`;

export const IGImage = styled.img`
  height: 144px;
  border-radius: 4px;
`;

export const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: 36px;
  justify-content: space-between;
  column-gap: 10%;
`;

export const ContactRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 36px;
`;

export const ContactRowLabel = styled.div`
  font-family: "Futura PT Medium";
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.04em;

  color: #ffffff;
  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ContactRowValue = styled.div`
  /* Body/medium */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.04em;

  color: #ffffff;

  white-space: pre-wrap;
  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const Social = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export const GifContainer = styled.div`
  flex: 0;
  flex-basis: 200px;

  display: flex;
  justify-content: flex-end;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    position: absolute;
    right: 5vw;
    bottom: 10vh;
  }
`;

export const ContactGif = styled.img`
  /* height: 200px; */
  width: 144px;
  display: block;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 80px;
    margin-top: 16px;
  }
`;

// Footer

export const SectionWithoutPadding = styled(Section)`
  padding: 0;
  height: 110vh;
  position: relative;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 0;
    height: 80vh;
    min-height: unset;
  }
`;

export const LastSectionContainer = styled.div`
  position: relative;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 110vh;
  width: 100vw;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 80vh;
    overflow: hidden;
  }

  /* overflow: hidden; */
`;

export const LastSectionLogo = styled.img`
  height: 180px;
  width: 220px;
  position: relative;
  z-index: 5;

  margin-top: -30vh;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 30vw;
    height: unset;
    margin-top: -20vh;
  }
`;

export const LastSectionImg = styled.img`
  width: 100vw;
  /* height: 100vh; */
  left: 0;
  right: 0;
  bottom: 0;
  /* bottom: -10vh; */
  /* top: 0; */
  position: absolute;
  z-index: 1;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 160vw;
    left: unset;
    right: unset;
  }
`;

export const Footer = styled.div`
  /* 2023© AKABEKO */

  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */
  letter-spacing: 0.04em;

  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;

  background-color: #e40032;

  position: absolute;
  bottom: 0;
  width: 100vw;
  z-index: 5;
  box-sizing: border-box;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 16px;
    line-height: 24px;
    padding: 20px;
  }
`;

export const MapWrapper = styled.div`
  overflow: hidden;
  max-width: 100%;
  width: 80vw;
  height: 500px;
  border-radius: 20px;
  z-index: 99;

  filter: grayscale(100%);

  transition: filter 0.3s;

  :hover {
    filter: unset;
  }
`;

export const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100%;

  img.text-marker {
    max-width: none !important;
    background: none !important;
  }
  img {
    max-width: none;
  }
`;

export const MapIframe = styled.iframe`
  height: 100%;
  width: 100%;
  border: 0;
`;

export const LastSectionCarousel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
`;

export const LastSectionCarouselImg = styled.img`
  height: 240px;
  width: 240px;
  border-radius: 20px;
  object-fit: cover;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 180px;
    width: 180px;
  }
`;

export const LastSectionText = styled.h3`
  /* New/H3 */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */
  text-align: center;

  /* White */
  color: #fcfcfc;
`;

export const InstaContainer = styled.div`
  padding-bottom: 160px;
  position: relative;
  z-index: 99;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding-bottom: 80px;
  }
`;

export const FollowOnInstaBtn = styled.button`
  box-sizing: border-box;
  background-color: #1a1717;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 60px;
  gap: 10px;

  /* Akabeko Red */
  border: 1px solid #e40032;
  color: #e40032;
  border-radius: 32px;

  /* New/Button */
  font-family: "Futura PT Book";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;

  cursor: pointer;

  transition: 0.2s;

  :hover {
    border-color: #fff;
    color: #fff;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 100%;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
  }
`;
